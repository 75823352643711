import React, { useEffect, useState, useRef } from "react"
import { navigate, Link } from "gatsby"
import useScrollPosition from "../scripts/useScrollPosition"

const Navbar = () => {
  const [navBarClass, setNavBarClass] = useState("")
  const firstUpdate = useRef(true)

  const [scroll, setScroll] = useState(0)
  const scrollPosition = useScrollPosition()
  scrollPosition !== scroll && setScroll(scrollPosition)

  useEffect(() => {
    if (!firstUpdate.current) {
      scroll > 20 ? setNavBarClass("dark_green_bg") : setNavBarClass("")
    } else {
      firstUpdate.current = false
      return
    }
  }, [scroll])

  return (
    <nav
      className={`${navBarClass} navbar navbar-expand-xl fixed-top navbar-dark`}
    >
      <div className="container-fluid container">
        <button
          className="navbar-toggler"
          style={{ borderColor: "#bbc5ba", color: "#bbc5ba" }}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className="navbar-toggler-icon"
            style={{ borderColor: "#bbc5ba" }}
          ></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/">
                <span>Home</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/#about">
                <span>About</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <div>
                <Link className="nav-link" aria-current="page" to="/#project">
                  <span>Projects</span>
                </Link>
                <div className="dropdown-menu">
                  <div className="nav-link-projects"></div>
                  <button
                    className="dropdown-item"
                    aria-current="page"
                    onClick={() => {
                      navigate("/projects")
                    }}
                  >
                    Programming
                  </button>
                  <button
                    className="dropdown-item"
                    aria-current="page"
                    onClick={() => {
                      navigate("/music")
                    }}
                  >
                    Music
                  </button>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/#skill">
                <span>Skills</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/#contact">
                <span>Contact</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/blog">
                <span>Blog</span>
              </Link>
            </li>
          </ul>

          <button
            className="download_resume_btn"
            onClick={() => {
              navigate("/resume")
            }}
          >
            Download My Portfolio
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
