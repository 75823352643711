import * as React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "index.md" }) {
        childrenMarkdownRemark {
          frontmatter {
            name
            social_media {
              href
              name
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { name, social_media } = data.file.childrenMarkdownRemark[0].frontmatter

  return (
    <footer className="dark_green_bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-8">
            <div className="left-footer-div">
              <button
                className="download_resume_btn"
                style={{ marginBottom: "8px" }}
                onClick={() => {
                  navigate("/resume")
                }}
              >
                Download My Portfolio
              </button>
              <p className="footer-text">
                {name} © {new Date().getFullYear()}
              </p>
            </div>
          </div>
          <div className="col-md-5 col-lg-4">
            <div
              className="d-flex flex-row mt-4 mb-1"
              style={{ marginLeft: "2px" }}
            >
              {social_media.map(media => (
                <a
                  href={media.href}
                  key={media.name}
                  className="nav-link px-1 pb-0"
                  aria-current="page"
                >
                  <GatsbyImage
                    image={getImage(media.image)}
                    alt={media.name}
                    className="footer-icon"
                  />
                </a>
              ))}
            </div>
            <p className="pt-2 footer-text">Follow me on social networks</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
